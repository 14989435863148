<template>
  <div v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.title }}
        </h2>
      </div>
      <div class="section__content">
        <div class="row px-5 justify-content-center align-items-stretch">
          <template v-for="(item, index) in getData.print_modes" :key="index">
            <div class="col-md-4">
              <div class="print-mode js-print-mode">
                <div class="print-mode__img">
                  <img :src="item.image" alt="">
                  <div class="new" v-if="item.new_icon" v-html="item.new_icon">
                  </div>
                  <div class="characteristics" v-if="item.characteristics" v-html="item.characteristics">
                  </div>
                  <div class="speed" v-html="item.speed"></div>
                </div>
                <div class="print-mode__text">
                  <h3>
                    {{ item.name }}
                  </h3>
                  <div class="print-mode__colors">
                    <div class="wrap-colors" v-html="item.image_colors"></div>
                    <div v-html="item.notes"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="index != Object.keys(getData.print_modes).length - 1"
              class="border--deco"></div>
          </template>

          <div class="col-12 text-center">
            <div class="print-conventions js-print-conventions">
              <template
                v-for="(item, index) in getData.conventions"
                :key="index">
                <div v-html="item.item"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'PrintModes',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_print_modes`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/printModes.scss';
</style>
