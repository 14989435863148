<template>
  <Subsection
    class="section--top partner-solutions"
    parent="Partner Solutions"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </Subsection>
</template>

<script>
import { filters } from '@/common/mixins';

import Subsection from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'PartnerSolutions',
  mixins: [filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    Subsection,
  },
  computed: {
    subSections() {
      /**
       * The url key inside each element MUST match the router path
       * see: @/router/index.js
       */
      return [
        {
          label: this.$i18n.t('partner-solutions.submenu.standard-options'),
          shareLabel: 'Standard Options',
          url: '',
        },
        {
          label: this.$i18n.t('partner-solutions.submenu.custom-finishing'),
          shareLabel: 'Finishing Solutions',
          url: 'custom-finishing',
        },
        {
          label: this.$i18n.t('partner-solutions.submenu.workflow'),
          shareLabel: 'Workflow',
          url: 'workflow',
        },
      ];
    },
  },
  methods: {
    changeSubSection(payload) {
      this.currentSubsection = payload;
    },
  },
};
</script>
