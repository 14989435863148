<template>
  <div class="row no-gutters align-items-stretch">
      <div class="col-sm-2">
        <div class="workflow-table__header">
          <img src="images/partner-solution/workflow/icon-business.png"
            alt="" class="icon">
          <h5>
            {{ $t('partner-solutions.workflow.columns.1') }}
          </h5>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__header">
          <img src="images/partner-solution/workflow/icon-creation.png"
            alt="" class="icon">
          <h5>
            {{ $t('partner-solutions.workflow.columns.2') }}
          </h5>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__header">
          <img src="images/partner-solution/workflow/icon-prepress.png"
            alt="" class="icon">
          <h5>
            {{ $t('partner-solutions.workflow.columns.3') }}
          </h5>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="workflow-table__header">
          <img src="images/partner-solution/workflow/icon-production.png"
            alt="" class="icon icon--big">
          <h5>
            {{ $t('partner-solutions.workflow.columns.4') }}
          </h5>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__header">
          <img src="images/partner-solution/workflow/icon-finishing.png"
            alt="" class="icon">
          <h5>
            {{ $t('partner-solutions.workflow.columns.5') }}
          </h5>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Workflow Table Headers',
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/partner-solutions/workflow.scss';
</style>
