<template>
  <div class="workflow-table">
    <Headers />

    <div class="row no-gutters">
      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_top_1"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_top_2"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_top_3"></div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_top_4"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_top_5"></div>
        </div>
      </div>

      <div class="col-12">
        <div class="workflow-table__headline">
          <h5>
            {{ data.addons_middle_subtitle }}
          </h5>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_bottom_1"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_bottom_2"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_bottom_3"></div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_bottom_4"></div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="workflow-table__body">
          <div v-html="data.addons_column_bottom_5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from './TableHeaders.vue';

export default {
  name: 'AddOns',
  props: {
    data: Object,
  },
  components: {
    Headers,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/partner-solutions/workflow.scss';
</style>
