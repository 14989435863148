<template>
  <div>
    <div
      :class="['virtual-tour__section', 'backdrop', 'backdrop--light', customClass]">
      <!-- eslint-disable max-len -->
      <div class="custom-bg js-custom-tour-bg" v-if="bg">
        <img :src="`${cmsURL}assets/${bg}`" />
      </div>

      <div class="virtual-tour__title text-center">
        <h2 class="title js-title">
          {{ data.headline }}
        </h2>
        <h5 class="title title--small js-subtitle" v-if="data.description">
          {{ data.description }}
        </h5>
      </div>

      <div class="inner-content" v-if="data.inner_points">
        <div class="container-fluid px-5">
          <div class="virtual-tour__points-holder">
            <div
              v-for="(item, index) in data.inner_points"
              :data-slide-to="index"
              href="#virtual-tour__carousel"
              :key="index">
              <Hotspot
                :class="[`hotspot--${index + 1}`,'js-hotspot']"
                data-target="#modal-tour-slider"
                data-toggle="modal"
                :gtmLabel="`Click Option ${item.point_letter}`"
                parent="Virtual Tour"
                :position="item.label_position"
                subSection="Press Station"
                :style="{
                  top: item.position_top,
                  left: item.position_left
                }"
                :title="item.point_label"
                @click="clickTracked($event)">
                {{ item.point_letter }}
              </Hotspot>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Carousel Bootstrap -->
    <Modal idModal="modal-tour-slider" >
      <div
        class="carousel slide js-carousel"
        id="virtual-tour__carousel"
        data-interval="false"
        data-ride="carousel"
        data-wrap="false" >
        <div class="carousel-inner">
          <div
            v-for="(item, index) in data.inner_points"
            :class="['carousel-item',
            {'carousel-item--note': item.note},
            {'active': index === 0}]"
            :key="index">
            <template v-if="item.media_items">
              <template v-if="item.media_items.length < 2">
                <Media
                  v-for="(media, index) in item.media_items"
                  class="js-media-wrapper"
                  :key="index"
                  :poster="media.poster"
                  :src="media.source"
                  :title="item.media_caption"
                  :type="media.type">
                  <template v-slot:content>
                    <div class="content" v-html="item.media_content"></div>
                  </template>
                  <template v-slot:note>
                    <small v-if="item.note">
                      {{ item.note }}
                    </small>
                  </template>
                </Media>
              </template>
              <template v-else>
                <div class="media-gallery">
                  <Media
                    v-for="(media, index) in item.media_items"
                    :class="['js-media-wrapper', `js-media-wrapper--${index}`,
                    {'active': index === 0}]"
                    :key="index"
                    :poster="media.poster"
                    :src="media.source"
                    :title="item.media_caption"
                    :type="media.type">
                    <template v-slot:content>
                      <div class="content" v-html="item.media_content"></div>
                    </template>
                    <template v-slot:note>
                      <small v-if="item.note">
                        {{ item.note }}
                      </small>
                    </template>
                  </Media>

                  <div class="media-gallery__thumbs">
                    <a
                      v-for="(media, index) in item.media_items"
                      :class="['single-thumb', 'js-media-nav',
                      {'active': index === 0}]"
                      href="javascript:void(0)"
                      :key="index"
                      @click="changeImg(`${index}`, $event.target)">
                      <img
                        :alt="item.media_caption"
                        class="img-fluid object-fit-cover-center"
                        :src="(media.type === 'video') ? media.poster : media.source" />
                    </a>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="text-only">
                <h4 class="title">
                  {{ item.media_caption }}
                </h4>

                <div v-html="item.media_content"></div>

                <small v-if="item.note">
                  {{ item.note }}
                </small>
              </div>
            </template>
          </div>
        </div>

        <template v-if="data.inner_points">
          <a
            v-if="data.inner_points.length > 1"
            class="carousel-control-prev js-prev"
            data-slide="prev"
            href="#virtual-tour__carousel"
            role="button">
            <span class="icon">
              <svg width="17px" height="17px" viewBox="0 0 17 17" >
                <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 "/>
              </svg>
            </span>
            <span class="page-name"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            v-if="data.inner_points.length > 1"
            class="carousel-control-next js-next active"
            data-slide="next"
            href="#virtual-tour__carousel"
            role="button">
            <span class="icon">
              <svg width="17px" height="17px" viewBox="0 0 17 17">
                <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 "/>
              </svg>
            </span>
            <span class="page-name"></span>
            <span class="sr-only">Next</span>
          </a>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import $ from 'jquery';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';
import { showElement } from '@/common/animations';

import Hotspot from '@/components/ui/VirtualTourPoint.vue';
import Media from '@/components/ui/Media.vue';
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'TourInnerPoints',
  mixins: [defaultMixins],
  components: {
    Hotspot,
    Media,
    Modal,
  },
  props: {
    bg: String,
    data: Object,
    customClass: String,
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
    };
  },
  mounted() {
    this.hideMainTabs();

    const mainTl = gsap.timeline();

    if ($('.js-custom-tour-bg').length > 0) {
      mainTl.to('.js-custom-tour-bg', {
        opacity: 1,
        duration: 0.3,
      });
    }

    mainTl.add(showElement('.js-title', 'top'))
      .add(showElement('.js-subtitle', 'top'))
      .add(showElement('.js-hotspot', 'top'));

    // Open Modal Event Listener
    $('#modal-tour-slider').on('show.bs.modal', () => {
      if ($('.js-carousel').find('.carousel-item.active').index() === 0) {
        $('.js-prev').removeClass('active');
      } else {
        $('.js-prev').addClass('active');
      }
    });

    // Modal Carousel event listener
    $('.js-carousel').on('slide.bs.carousel', (e) => {
      const thisCarousel = $('.js-carousel');
      const slidingItemsAsIndex = thisCarousel.find('.carousel-item').length - 1;

      // If first item hide prev arrow
      if ($(e.relatedTarget).index() === 0) {
        $('.js-prev').removeClass('active');
      } else {
        $('.js-prev').addClass('active');
      }

      // If last item hide next arrow
      if ($(e.relatedTarget).index() === slidingItemsAsIndex) {
        $('.js-next').removeClass('active');
      } else {
        $('.js-next').addClass('active');
      }
    });
  },
  beforeUnmount() {
    if ($('.js-custom-tour-bg').length > 0) {
      gsap.timeline()
        .to('.js-custom-tour-bg', {
          opacity: 0,
          duration: 0.5,
        });
    }
  },
  methods: {
    // Change item if is gallery
    changeImg(target, _this) {
      const video = document.getElementsByTagName('video');

      if (video.length > 0) {
        for (let index = 0; index < video.length; index += 1) {
          video[index].pause();
        }
      }

      $('.js-media-nav').removeClass('active');
      $(_this).parent().addClass('active');

      $('.js-media-wrapper').hide().removeClass('active');
      $(`.js-media-wrapper--${target}`).fadeIn().addClass('active');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/virtual-tour/sections.scss';
</style>
