<template>
  <div class="section--top customer-stories">
    <transition name="fade">
      <Loading v-if="!isReady" />
    </transition>

    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('main-nav.customer-stories')}}
        </h2>
      </div>

      <div class="section__content">
        <div class="row">
          <div class="col-md-3">
            <div class="wrap-sidebar-filter">
              <span>
                {{ $t('filters.filtering-options') }}
              </span>

              <div class="wrap-input-search">
                <div class="search-input ">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="none">
                      <!-- eslint-disable-next-line max-len -->
                      <path d="M13.5792 11.9497H12.721L12.4168 11.6564C13.4814 10.418 14.1224 8.81018 14.1224 7.06118C14.1224 3.16124 10.9611 0 7.06118 0C3.16124 0 0 3.16124 0 7.06118C0 10.9611 3.16124 14.1224 7.06118 14.1224C8.81018 14.1224 10.418 13.4814 11.6564 12.4168L11.9497 12.721V13.5792L17.3814 19L19 17.3814L13.5792 11.9497ZM7.06118 11.9497C4.3562 11.9497 2.17267 9.76615 2.17267 7.06118C2.17267 4.3562 4.3562 2.17267 7.06118 2.17267C9.76615 2.17267 11.9497 4.3562 11.9497 7.06118C11.9497 9.76615 9.76615 11.9497 7.06118 11.9497Z"></path>
                    </svg>

                  <input
                    type="search"
                    name="search-stories"
                    id="search-stories"
                    :placeholder="$t('filters.search')"
                    v-model="keyword">

                  <svg
                    v-if="keyword"
                    :aria-hidden="!keyword"
                    role="img"
                    class="close"
                    width="19"
                    height="19"
                    viewBox="0 0 24 24"
                    @click="keyword = ''">
                    <!-- eslint-disable-next-line max-len -->
                    <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                  </svg>
                </div>
              </div>

              <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.industry') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('industry')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.industry_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.industry">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div>

              <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.region') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('region')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.region_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.region">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div>

              <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.focus') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('equipment')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.equipment_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.equipment">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <template v-if="filteredCards.length">
              <div class="row">
                <div
                  class="col-sm-6 col-lg-4 col-xl-3"
                  v-for="(item, index) in filteredCards"
                  :key="index" >
                  <Card
                    class="data-story-card"
                    :data="item"
                    data-section="Customer Stories"
                    data-subsection=""
                    :id-card="index"
                    ref="card"
                    :share-section="`${$t('main-nav.customer-stories')}`"
                    target-modal="#modal-story"
                    @share-card="shareThis"
                    @show-modal="setOpen" />
                  </div>
              </div>
            </template>
            <template v-else>
              <div class="empty-msg">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24">
                  <!-- eslint-disable-next-line max-len -->
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 11.5v5m0-8.99l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10Z"/>
                </svg>
                <p class="title">
                  No stories to show
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <ModalStory
      :data="filteredCards[itemOpen] || {}"
      :index="itemOpen || 0"
      :data-section="'Customer Stories'"
      idModal="modal-story"
      modal-type="story"
      @share-card="shareThis"
      @close-modal="clearLinks"
      @change-modal="changeModal"></ModalStory>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins, cardsMixins } from '@/common/mixins';

import Loading from '@/components/ui/Loading.vue';
import Card from '@/components/card/Card.vue';
import ModalStory from '@/components/modal/ModalStory.vue';

export default {
  name: 'CustomerStories',
  mixins: [filters, defaultMixins, cardsMixins],
  components: {
    Loading,
    Card,
    ModalStory,
  },
  data() {
    return {
      filters: {
        industry: [],
        region: [],
        equipment: [],
      },
      items: [],
      selectedFilters: {
        industry: [],
        region: [],
        equipment: [],
      },
      keyword: '',
    };
  },
  created() {
    // Get all industries
    axios.get(`${endpoints.CMS}items/industry`, {
      params: {
        fields: '*,translations.*',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => { this.filters.industry = data; });

    // Get all Regions
    axios.get(`${endpoints.CMS}items/region`, {
      params: {
        fields: '*,translations.*',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => { this.filters.region = data; });

    // Get all Equipments
    axios.get(`${endpoints.CMS}items/equipment`, {
      params: {
        fields: '*,translations.*',
        sort: 'sort',
        'filter[status][_eq]': 'published',
      },
    })
      .then(({ data: { data } }) => { this.filters.equipment = data; });

    // Get all Cards
    axios.get(`${endpoints.CMS}items/customer_stories`, {
      params: {
        fields: `*,
          translations.*,
          filters_industry.industry_id.*,
          filters_industry.industry_id.translations.*,
          filters_region.region_id.*,
          filters_region.region_id.translations.*,
          filters_equipment.equipment_id.*,
          filters_equipment.equipment_id.translations.*`,
        sort: 'identifier',
        'filter[status][_eq]': 'published',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => { this.items = data; })
      .then(() => { this.isReady = true; });
  },
  mounted() {
    this.hideMainTabs();
  },
  updated() {
    this.matchHeight('.data-story-card .category');
    this.matchHeight('.data-story-card .title');
  },
  methods: {
    clearLinks() {
      this.itemOpen = null;
    },
    changeModal(direction) {
      const totalItems = this.filteredCards.length - 1;

      this.updateShared(this.$store.state.sharedList);

      if (direction === 'prev') {
        if (this.itemOpen === 0) {
          this.itemOpen = totalItems;
        } else {
          this.itemOpen -= 1;
        }
      } else if (this.itemOpen === totalItems) {
        this.itemOpen = 0;
      } else {
        this.itemOpen += 1;
      }
    },
  },
  watch: {
    keyword(newVal) {
      const hasKeyword = newVal !== '';
      const keywordEnough = newVal.length > 2;

      if (hasKeyword && keywordEnough) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    // Print filters filtered with current locale
    translatedFilters() {
      return (cat) => this.filters[cat]
        .map((filter) => {
          // Set English for fallback
          const translatedFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return translatedFilter || fallbackFilter;
        });
    },
    // Filter the translated data inside the cards
    translatedCards() {
      return this.items
        .map((card) => {
          // Set English for fallback
          const translatedCard = card.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackCard = card.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            ...card,
            content: translatedCard || fallbackCard,
          };
        })
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              industry: this.getTranslatedFilter(card, 'industry'),
            },
          }
        ))
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              equipment: this.getTranslatedFilter(card, 'equipment'),
            },
          }
        ))
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              region: this.getTranslatedFilter(card, 'region'),
            },
          }
        ));
    },
    // Return cards after filtering in the front-end
    filteredCards() {
      const hasindustry = this.selectedFilters.industry.length;
      const hasRegion = this.selectedFilters.region.length;
      const hasEquipment = this.selectedFilters.equipment.length;
      let allData = this.translatedCards;
      // Search bar
      const hasKeyword = this.keyword !== '';
      const keywordEnough = this.keyword.length > 2;

      if (hasKeyword && keywordEnough) {
        allData = allData.filter((item) => {
          const postName = item.content.title.toUpperCase();
          return postName.includes(this.keyword.toUpperCase());
        });
      }

      if (hasindustry === 0 && hasRegion === 0 && hasEquipment === 0) {
        return allData;
      }

      return allData
        .filter((card) => this.doFilter(card, 'industry'))
        .filter((card) => this.doFilter(card, 'region'))
        .filter((card) => this.doFilter(card, 'equipment'));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/customer-stories.scss';
</style>
