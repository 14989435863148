<template>
  <div v-if="info">
    <div class="container-xl">
      <div
        class="carousel slide carousel-fade js-carousel"
        id="tech-specs__carousel"
        data-interval="false"
        data-ride="carousel"
        data-wrap="true">
        <div class="carousel-inner">
          <div
            v-for="(item, index) in getDataByPrinter"
            :class="[
              'carousel-item',
              'px-5',
              {'active': index === 0}
              ]"
            :key="index">
            <div class="row">
              <transition name="fade" mode="out-in">
                <div class="specs-bg">
                  <img :src="`${cmsURL}assets/${getDataByPrinter[index].background}`">
                </div>
              </transition>
              <div class="offset-md-3 offset-lg-5 col-md-9 col-lg-7">
                <div class="specs__headline">
                  <h2 class="title">
                    {{ getData(index).headline }}
                  </h2>
                  <p
                    v-if="getData(index).subheadline"
                    class="subtitle">
                    {{ getData(index).subheadline }}
                  </p>
                </div>
                <br>
                <div class="section__content specs-table">
                  <div class="specs-row">
                    <div class="row">
                      <div class="col-sm-3">
                        <strong>
                          {{ getData(index).headline_content_1 }}
                        </strong>
                      </div>
                      <div class="col-sm-9">
                        <div class="specs-inner-row" v-for="(row, idx) in getData(index).content_1" :key="idx">
                          <div class="row">
                            <div class="col-sm-5" v-html="row.sub_headline"></div>
                            <div class="col-sm-7" v-html="row.content"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="specs-row">
                    <div class="row">
                      <div class="col-sm-3">
                        <strong>
                          {{ getData(index).headline_content_2 }}
                        </strong>
                      </div>
                      <div class="col-sm-9">
                        <div class="specs-inner-row" v-for="(row, idx) in getData(index).content_2" :key="idx">
                          <div class="row">
                            <div class="col-sm-5" v-html="row.sub_headline"></div>
                            <div class="col-sm-7" v-html="row.content"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="specs-row" v-for="(row, idx) in getData(index).content_3" :key="idx">
                    <div class="row">
                      <div class="col-sm-3" v-html="row.sub_headline"></div>
                      <div class="col-sm-9" v-html="row.content"></div>
                    </div>
                  </div>
                </div>
                <div class="specs-footnote" v-html="getData(index).notes"></div>
              </div>

              <ShareButton
                v-if="getData(index).shareable_pdf"
                :shareLink="`${cmsURL}assets/${getData(index).shareable_pdf}`"
                :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.tech-specs')} ${printerName} (${getData(index).subheadline})`"
                :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.tech-specs')} ${printerName} ${getData(index).subheadline}`)"
                @share-this="shareThis" />
            </div>
          </div>
        </div>

        <a
          v-if="getDataByPrinter.length > 1"
          class="carousel-control carousel-control-prev js-prev"
          data-slide="prev"
          href="#tech-specs__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17" >
              <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 "/>
            </svg>
          </span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          v-if="getDataByPrinter.length > 1"
          class="carousel-control carousel-control-next js-nex"
          data-slide="next"
          href="#tech-specs__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17">
              <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 "/>
            </svg>
          </span>
          <span class="sr-only">Next</span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'TechnicalSpecs',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_technical_specs`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  computed: {
    // Get current Printer's data
    getDataByPrinter() {
      if (this.info) {
        const currentPrinterData = this.info
          .filter((item) => item.printer
            .some((printer) => printer.printers_id === this.currentPrinter));

        return currentPrinterData;
      }

      return false;
    },
    // Get Translated data
    getData() {
      return (idx) => {
        if (this.info) {
          // Set fallback to english data
          const [defaultEnglishData] = this.getDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.fallbackLocale);

          // Get current language data
          const [translatedData] = this.getDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.locale);

          return translatedData || defaultEnglishData;
        }

        return false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/technicalSpecs.scss';
</style>
