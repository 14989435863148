<template>
  <Subsection
    class="section--top technology"
    :class="{'bg-black': darkBg }"
    parent="Technology"
    :sidebar="sidebar"
    :subSections="subSections">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
  </Subsection>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

import Subsection from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'Technology',
  mixins: [defaultMixins, filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    Subsection,
  },
  computed: {
    subSections() {
      const mapOfSections = {
        1: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.brilliant-ink'),
            shareLabel: 'HP Brilliant Ink',
            url: 'brilliant-ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
        2: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.ink'),
            shareLabel: 'HP A50 Ink T490',
            url: 'ink',
          },
          {
            label: this.$i18n.t('technology.submenu.brilliant-ink2'),
            shareLabel: 'HP Brilliant Ink',
            url: 'brilliant-ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
        3: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.brilliant-ink'),
            shareLabel: 'HP Brilliant Ink',
            url: 'brilliant-ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
      };

      return mapOfSections[this.currentPrinter];
    },
    darkBg() {
      const isPaperPath = this.$route.path.indexOf('paper-path') > 0;
      const isAdvantage = this.currentPrinter === 3;
      return isPaperPath && isAdvantage;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bg-black {
    background-color: black;
  }
</style>
