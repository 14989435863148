<template>
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="nozzle__img js-nozzle-img">
        <span>
          {{ $t('technology.HP-TIJ.redundancy.img-label') }}
        </span>
        <img src="images/technology/nozzle-redundancy.png" alt="">
        <div class="nozzle__img__copy">
          <img src="images/technology/arrow.svg" alt="" class="arrow" inline>
          <p class="title title--medium title--blue">
            {{ $t('technology.HP-TIJ.redundancy.web') }}
          </p>
          <p>
            HP TIJ Printheads
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NozzleRedundancy',
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/tij/nozzle-redundancy.scss';
</style>
