<template>
  <Modal :idModal="idModal">
    <div
      class="modal-arrow arrow-prev"
      v-if="$parent.filteredCards.length > 1"
      @click="$emit('change-modal', 'prev')">
      <svg width="17px" height="17px" viewBox="0 0 17 17">
        <g transform="translate(-13.000000, -12.000000)" fill="#0072AD" fill-rule="nonzero">
          <g
            transform="translate(22.000000, 20.000000)
            scale(-1, 1)
            translate(-22.000000, -20.000000)
            translate(2.000000, 0.000000)">
            <polygon
              points="20.35 28.7 28.7 20.35 20.35 12 18.875 13.475
              24.7 19.325 12 19.325 12 21.375 24.7 21.375 18.875 27.225">
            </polygon>
          </g>
        </g>
      </svg>
    </div>
    <div
      class="modal-arrow arrow-next"
      v-if="$parent.filteredCards.length > 1"
      @click="$emit('change-modal', 'next')">
      <svg width="17px" height="17px" viewBox="0 0 17 17">
        <g transform="translate(-12.000000, -12.000000)" fill="#0072AD" fill-rule="nonzero">
          <polygon
            points="20.35 28.7 28.7 20.35 20.35 12 18.875
            13.475 24.7 19.325 12 19.325 12 21.375 24.7 21.375 18.875 27.225">
          </polygon>
        </g>
      </svg>
    </div>
    <div class="wrap-modal-story">
      <div class="row">
        <div class="col-md-3">
          <div class="modal-story__sidebar">
            <img :src="`${cmsURL}assets/${data.logo}`" alt="">

            <div class="content">
              <div v-if="data.filters">
                <h5>
                  {{ $t('filters.region') }}:
                </h5>
                <p v-for="(region, index) in data.filters.region" :key="index">
                  {{ region.name }}
                </p>
              </div>

              <div v-if="data.content">
                <h5 v-if="data.content.country">
                  {{ $t('filters.country') }}:
                </h5>
                <p>
                  {{ data.content.country }}
                </p>
              </div>

              <div v-if="data.filters">
                <h5>
                  {{ $t('filters.industry') }}:
                </h5>
                <p>
                  <template v-for="(industry, index) in  data.filters.industry" :key="index">
                    {{ industry.name }}
                    <span v-if="index != data.filters.industry.length - 1">, </span>
                  </template>
                </p>
              </div>

              <div v-if="data.content">
                <h5 v-if="data.content.equipment_name">
                  {{ $t('filters.equipment') }}:
                </h5>
                <p>
                  {{ data.content.equipment_name }}
                </p>
              </div>

              <template v-if="data.video">
                <a :href="data.video" target="_blank"
                  class="cta cta--last data-video ra-gtm-event" :data-gtm-section="dataSection"
                  :data-gtm-subsection="dataSubsection"
                  :data-gtm-label="`${data.identifier} - Watch Video`">
                    {{ $t('buttons.watch-video') }}
                </a>
                <br>
              </template>

              <template v-if="data.content">
                <a
                  v-if="data.content.fullstory_pdf"
                  class="cta cta--last data-story ra-gtm-event"
                  :data-gtm-section="dataSection"
                  :data-gtm-subsection="dataSubsection"
                  :data-gtm-label="`${data.identifier} - Read the Full Story`"
                  :href="`${cmsURL}assets/${data.content.fullstory_pdf}`"
                  target="_blank">
                  {{ $t('buttons.full-story') }}
                </a>
                <br v-if="data.content.fullstory_pdf">
              </template>

              <a v-if="data.article_link"
                class="cta cta--last data-link ra-gtm-event"
                :data-gtm-section="dataSection"
                :data-gtm-subsection="dataSubsection"
                :data-gtm-label="`${data.identifier} - Visit Link`"
                :href="data.article_link"
                target="_blank">
                {{ $t('buttons.article') }}
              </a>

            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="modal-story__content" v-if="data.content">
            <div class="img" :style="{backgroundImage: `url(${cmsURL}assets/${data.image})`}">
              <div>
                <h4 class="title title--medium">{{ data.content.title }}</h4>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-sm-7">
                  <h4 class="title title--small">
                    {{ data.content.title_content }}
                  </h4>
                  <p v-html="data.content.content"></p>
                </div>
                <div class="col-sm-5">
                  <div class="quote">
                    <div class="quote__quote">
                      <p>
                        {{ data.content.text_quote }}
                      </p>
                    </div>
                    <div
                      v-if="data.content.client_name"
                      v-show="data.content.client_name !== '0'"
                      class="quote__client">
                      <h5>
                        {{ data.content.client_name }}
                      </h5>
                      <p v-html="data.content.client_title"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="data.content">
      <ShareButton
        v-if="data.content.shareable_pdf"
        class="share-button--modal"
        :shareLink="`${cmsURL}assets/${data.content.shareable_pdf}`"
        :shareLabel="normalizedLabel"
        :hash="hashify(normalizedLabel)"
        @share-this="shareThis" />
    </template>

  </Modal>
</template>

<script>
import { filters, defaultMixins } from '@/common/mixins';
import ShareButton from '@/components/ui/ShareButton.vue';
import Modal from './Modal.vue';

export default {
  name: 'ModalStory',
  mixins: [filters, defaultMixins],
  props: {
    data: {
      required: true,
      type: Object,
    },
    idModal: {
      type: String,
    },
    modalType: {
      type: String,
    },
    index: {
      required: true,
      type: Number,
    },
    dataSection: {
      type: String,
    },
    dataSubsection: {
      type: String,
    },
  },
  components: {
    Modal,
    ShareButton,
  },
  methods: {
    emitShare(payload) {
      this.$emit('share-card', payload);
    },
  },
  computed: {
    normalizedLabel() {
      const prefix = (this.dataSubsection) ? this.dataSubsection : this.dataSection;
      const { data: { content: { title } } } = this;

      return `${prefix} - ${title}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/components/modal/modalStory.scss';
</style>
