<template>
  <div class="team">
    <!-- eslint-disable max-len  -->

    <!-- Back Button -->
    <router-link
      class="go-back d-flex"
      to="/">
      <svg class="mr-2" width="16px" height="25px" viewBox="0 0 16 25">
        <path class="st0" d="M10.8,2.5C10.8,3.9,12,5,13.4,5S16,3.9,16,2.5S14.8,0,13.4,0S10.8,1.1,10.8,2.5"/>
        <path class="st0" d="M10.8,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,10,13.4,10S10.8,11.1,10.8,12.5"/>
        <path class="st0" d="M10.8,22.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,20,13.4,20S10.8,21.1,10.8,22.5"/>
        <path class="st0" d="M5.7,7.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,5,8.3,5S5.7,6.1,5.7,7.5"/>
        <path class="st0" d="M5.7,17.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,15,8.3,15S5.7,16.1,5.7,17.5"/>
        <path class="st0" d="M0.5,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S4.5,10,3.1,10S0.5,11.1,0.5,12.5"/>
      </svg>
      {{ $t('common.return') }}
    </router-link>

    <div class="container-xl text-center">
      <h2 class="title">
        {{ $t('main-sidebar.meet-team') }}
      </h2>
      <br>
      <div class="section__content">
        <div class="meet-team">
          <div class="row align-items-start justify-content-center">

            <div :class="['meet-team-card', memberClass]">
              <div class="meet-team-card__img">
                <img
                :alt="`${salesPerson.firstname} ${salesPerson.lastname}`"
                :src="salesPerson.photo || 'images/headshot.jpg'" >
              </div>
              <div class="meet-team-card__info">
                <h4>
                  {{ salesPerson.firstname }} {{ salesPerson.lastname }}
                </h4>
                <p>
                  {{ salesPerson.title }}
                </p>
              </div>
            </div>

            <!-- Repeater item -->
            <div
              v-for="(item, index) in salesPerson.team"
              :class="['meet-team-card', memberClass]"
              :key="index">
              <div class="meet-team-card__img">
                <img
                  :alt="`${item.firstname} ${item.lastname}`"
                  :src="item.photo || 'images/headshot.jpg'">
              </div>
              <div class="meet-team-card__info">
                <h4>
                  {{ item.firstname }} {{ item.lastname }}
                </h4>
                <p>
                  {{ item.title }}
                </p>
              </div>
            </div>
            <!-- End Repeater item -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Team',
  methods: {
    goBack() {
      this.$store.commit('changeCurrentSection', 'Intro');
    },
  },
  computed: {
    ...mapState([
      'salesPerson',
    ]),
    memberClass() {
      return (this.salesPerson.team.length === 4 || this.salesPerson.team.length === 5 || this.salesPerson.team.length === 8) ? 'even-row' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/team.scss';
</style>
