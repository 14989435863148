<template>
  <div>
    <!-- eslint-disable max-len -->
    <div class="container-xl">
      <div :class="['section__title', 'text-center',
      {'title--space': currentPrinter === 2}]">
        <h2 class="title">
          {{ $t('technology.paper-path.title') }}
        </h2>
      </div>
      <div class="section__content">
        <div
          class="paper-path"
          :class="{'paper-path--small': currentPrinter === 3}">
          <img
            v-if="getDataByPrinter.printer_image"
            class="paper-path__printer"
            :src="`${cmsURL}assets/${getDataByPrinter.printer_image}`"
            alt="" >
          <img
            v-if="getDataByPrinter.base_rollers"
            class="paper-path__rolls paper-path__rolls--base"
            :src="`${cmsURL}assets/${getDataByPrinter.base_rollers}`"
            alt="">
          <img
            v-if="getDataByPrinter.surface_roller"
            :class="['paper-path__rolls', {'active': rollers === 'surface'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.surface_roller}`"
            alt="">
          <img
            v-if="getDataByPrinter.load_cell"
            :class="['paper-path__rolls', {'active': rollers === 'load'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.load_cell}`"
            alt="">
          <img
            v-if="getDataByPrinter.nip_roller"
            :class="['paper-path__rolls', {'active': rollers === 'nip'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.nip_roller}`"
            alt="">
          <img
            v-if="getDataByPrinter.additional"
            class="additional"
            :src="`${cmsURL}assets/${getDataByPrinter.additional}`"
            alt="">

          <div
            v-if="getDataByPrinter.svg_paper_path"
            class="paper-path__line"
            v-html="getDataByPrinter.svg_paper_path"></div>
        </div>

        <div class="paper-path__controls">
          <div
            v-if="getDataByPrinter.base_rollers"
            class="single-path-control"
            @mouseover="changeImg('surface')"
            @mouseleave="changeImg('init')" >
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-1') }}
            </span>
          </div>
          <div
            v-if="getDataByPrinter.load_cell"
            class="single-path-control single-path-control--load-cell"
            @mouseover="changeImg('load')"
            @mouseleave="changeImg('init')" >
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-2') }}
            </span>
          </div>
          <div
            v-if="getDataByPrinter.nip_roller"
            class="single-path-control single-path-control--nip"
            @mouseover="changeImg('nip')"
            @mouseleave="changeImg('init')" >
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-3') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="getData"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.paper-path')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.paper-path')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

gsap.registerPlugin(DrawSVGPlugin);

export default {
  name: 'PaperPath',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      info: null,
      rollers: 'init',
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_paper_path`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  updated() {
    gsap.timeline()
      .fromTo('.js-animate-path', {
        drawSVG: '0 3%',
      }, {
        duration: 12,
        drawSVG: '97% 100%',
        ease: 'linear',
      })
      .repeat(-1);
  },
  methods: {
    changeImg(state) {
      this.rollers = state;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/paperPath.scss';
</style>
