<template>
  <div class="main-nav js-main-nav">
    <div class="container-xl d-flex align-items-center justify-content-center">
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Intro"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.intro") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Virtual Tour"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/virtual-tour"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.virtual-tour") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Technology"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/technology"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.technology") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Partner Solutions"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/partner-solutions"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.partner-solutions") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Upgradability"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        :to="(currentPrinter === 3) ? '/scalability/configuration-options' : '/upgradability'"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          <template v-if="currentPrinter === 3">
            {{ $t("main-nav.scalability") }}
          </template>
          <template v-else>
            {{ $t("main-nav.upgradability") }}
          </template>
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Service & Support"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/service-support"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.service-support") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Customer Stories"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/customer-stories"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.customer-stories") }}
        </span>
      </router-link>
      <router-link
        active-class="active"
        class="single-nav ra-gtm-event"
        data-gtm-section="Library"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/library"
        @click="closeSidebar(); clickTracked($event)">
        <span>
          {{ $t("main-nav.library") }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'MainNav',
  mixins: [defaultMixins],
  methods: {
    closeSidebar() {
      this.$parent.closeShare();
      this.$parent.isSidebarOpen = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/scss/components/mainNav.scss';
</style>
