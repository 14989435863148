<template>
  <div class="feedback">
    <!-- eslint-disable max-len  -->
    <!-- Back Button -->
    <router-link
      class="go-tour d-flex"
      to="/">
      <svg class="mr-2" width="16px" height="25px" viewBox="0 0 16 25">
        <path class="st0" d="M10.8,2.5C10.8,3.9,12,5,13.4,5S16,3.9,16,2.5S14.8,0,13.4,0S10.8,1.1,10.8,2.5"/>
        <path class="st0" d="M10.8,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,10,13.4,10S10.8,11.1,10.8,12.5"/>
        <path class="st0" d="M10.8,22.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S14.8,20,13.4,20S10.8,21.1,10.8,22.5"/>
        <path class="st0" d="M5.7,7.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,5,8.3,5S5.7,6.1,5.7,7.5"/>
        <path class="st0" d="M5.7,17.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S9.7,15,8.3,15S5.7,16.1,5.7,17.5"/>
        <path class="st0" d="M0.5,12.5c0,1.4,1.2,2.5,2.6,2.5s2.6-1.1,2.6-2.5S4.5,10,3.1,10S0.5,11.1,0.5,12.5"/>
      </svg>
      {{ $t('common.return') }}
    </router-link>
    <!-- // Back Button -->

    <div class="container-xl">
      <div class="feedback__header" v-show="!isFormSubmitted">
        <h2 class="title">
          {{ $t('main-sidebar.feedback') }}
        </h2>
        <div class="content">
          <p>
            {{ $t('feedback.headline') }}
          </p>
        </div>
      </div>

      <div class="feedback__content">
        <form
          action=""
          class="form-feedback"
          v-show="!isFormSubmitted">
          <div class="row">
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label
                  id="flabel1"
                  :class="['title', 'title--small', { 'error': errors.feedback1 }]">
                  1. {{ $t('feedback.label-1') }}
                </label>

                <div class="slider-wrapper">
                  <input
                    class="rangeslider"
                    id="feedback1"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    v-model="formData.feedback1">
                  <span class="label">
                    {{ $t('feedback.options.1') }}
                  </span>
                  <span class="label label--right">
                    {{ $t('feedback.options.6') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label
                  id="flabel2"
                  :class="['title', 'title--small', { 'error': errors.feedback2 }]">
                  2. {{ $t('feedback.label-2') }}
                </label>

                <div class="slider-wrapper">
                  <input
                    class="rangeslider"
                    id="feedback2"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    v-model="formData.feedback2">
                  <span class="label">
                    {{ $t('feedback.options.1') }}
                  </span>
                  <span class="label label--right">
                    {{ $t('feedback.options.6') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label
                  id="flabel3"
                  :class="['title', 'title--small', { 'error': errors.feedback3 }]">
                  3. {{ $t('feedback.label-3') }}
                </label>

                <div class="slider-wrapper">
                  <input
                    class="rangeslider"
                    id="feedback3"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    v-model="formData.feedback3">
                  <span class="label">
                    {{ $t('feedback.options.1') }}
                  </span>
                  <span class="label label--right">
                    {{ $t('feedback.options.6') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label
                  id="flabel4"
                  :class="['title', 'title--small', { 'error': errors.feedback4 }]">
                  4. {{ $t('feedback.label-4') }}
                </label>

                <div class="slider-wrapper">
                  <input
                    class="rangeslider"
                    id="feedback4"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    v-model="formData.feedback4">
                  <span class="label">
                    {{ $t('feedback.options.1') }}
                  </span>
                  <span class="label label--right">
                    {{ $t('feedback.options.6') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label
                  id="flabel5"
                  :class="['title', 'title--small', { 'error': errors.feedback5 }]">
                  5. {{ $t('feedback.label-5') }}
                </label>

                <div class="row">
                  <div class="col-sm-6">
                    <label for="excellent" class="radio-btn">
                      <input
                        type="radio"
                        name="feedback5"
                        id="excellent"
                        value="Excellent"
                        v-model="formData.feedback5">
                      {{ $t('feedback.options.5') }}
                    </label>
                    <label for="very-good" class="radio-btn">
                      <input
                        type="radio"
                        name="feedback5"
                        id="very-good"
                        value="Very Good"
                        v-model="formData.feedback5">
                      {{ $t('feedback.options.4') }}
                    </label>
                    <label for="good" class="radio-btn">
                      <input
                        type="radio"
                        name="feedback5"
                        id="good"
                        value="Good"
                        v-model="formData.feedback5">
                      {{ $t('feedback.options.3') }}
                    </label>
                  </div>
                  <div class="col-sm-6">
                    <label for="not-good" class="radio-btn">
                      <input
                        type="radio"
                        name="feedback5"
                        id="not-good"
                        value="Not so Good"
                        v-model="formData.feedback5">
                      {{ $t('feedback.options.2') }}
                    </label>
                    <label for="poor" class="radio-btn">
                      <input
                        type="radio"
                        name="feedback5"
                        id="poor"
                        value="Poor"
                        v-model="formData.feedback5">
                      {{ $t('feedback.options.1') }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-feedback__input">
                <label id="flabel6" class="title title--small">
                  6. {{ $t('feedback.label-6') }}
                </label>
                <textarea
                  id="feedback6"
                  name="feedback6"
                  cols="30"
                  rows="10"
                  :placeholder="$t('feedback.comments')"
                  v-model="formData.feedback6"
                  @focus="removePlaceholder($event.target)"></textarea>
              </div>
            </div>
            <div class="col-12">
              <button class="cta submitted" type="button" @click="validateForm">
                {{ $t('feedback.submit') }}
              </button>
            </div>
          </div>
        </form>

        <div class="feedback-ty" v-show="isFormSubmitted">
          <h3 class="title--medium">
            {{ $t('feedback.thankyou.headline') }}
          </h3>
          <p>
            {{ $t('feedback.thankyou.content') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';

export default {
  name: 'Feedback',
  data() {
    return {
      isFormSubmitted: false,
      isFormValid: true,
      errors: {
        feedback1: false,
        feedback2: false,
        feedback3: false,
        feedback4: false,
        feedback5: false,
        feedback6: false,
      },
      formData: {
        email: '',
        feedback1: null,
        feedback2: null,
        feedback3: null,
        feedback4: null,
        feedback5: null,
        feedback6: '',
      },
    };
  },
  methods: {
    goToHome() {
      this.$store.commit('changeCurrentSection', 'Intro');
    },
    removePlaceholder(input) {
      input.removeAttribute('placeholder');
    },
    removeErrors() {
      Object.keys(this.errors).map((key) => {
        this.errors[key] = false;
        return key;
      });
    },
    updateErrors() {
      this.removeErrors();
      this.isFormValid = true;
      // Validate each field
      // If no value set to false
      Object.entries(this.formData).map((i) => {
        if (i[1] === null) {
          this.errors[i[0]] = true;
          this.isFormValid = false;
          return false;
        }

        return i;
      });
    },
    validateForm() {
      this.updateErrors();

      this.submitForm();
    },
    submitForm() {
      if (this.isFormValid) {
        this.formData.email = this.salesPerson.email;
        axios.post(
          `${endpoints.BASEAPIURL}mailfunctions${endpoints.EXT}`,
          {
            action: 'feedback',
            lang: this.$i18n.locale,
            ...this.formData,
          },
        )
          .then((response) => {
            console.log(response);
            const json = response.data;
            if (json.reply === 1) {
              this.isFormSubmitted = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  watch: {
    formData: {
      handler() {
        this.updateErrors();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'salesPerson',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/feedback.scss';
</style>
