<template>
  <div class="service-and-support" v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.section_headline }}
        </h2>
      </div>
      <div class="section__content">
        <div class="support-timeline">
          <div class="single-support single-support--small">
            <div
              class="single-support__foot single-support__foot--small"
              :class="`single-support__foot--small-${labelClass}`">
              <span>
                {{ getData.stage_0_label }}
              </span>
            </div>
          </div>
          <div class="single-support">
            <div class="single-support__content">
              <img class="image" src="images/service-support/icon-pm.png" alt="">
              <h4 data-service-title>
                {{ getData.stage_1_headline }}
              </h4>
              <div
                :class="['hidden', {'big': $i18n.locale === 'fr'}]">
                <div v-html="getData.stage_1_content"></div>

                <template v-if="getData.stage_1_content_additional">
                  <span
                    class="learn-more"
                    data-toggle="modal"
                    data-target="#additional-modal"
                    @click="setAdditionalContent(1)">
                    {{ $t("buttons.learn-more") }}
                  </span>
                </template>
              </div>

              <span
                class="icon"
                @click="openDetail($event)" >
              </span>
            </div>
            <div class="single-support__foot">
              <span>
                {{ getData.stage_1_duration }}
              </span>
            </div>
          </div>
          <div class="single-support">
            <div
              class="single-support__cta ra-gtm-event"
              data-toggle="modal"
              data-target="#preparation-modal"
              data-gtm-section="Service & Support"
              data-gtm-subsection=""
              data-gtm-label="Open Site preparation Modal"
              @click="clickTracked($event)">
              <span>
                {{ getData.site_prep_trigger_label }}
              </span>
            </div>
            <div class="single-support__content single-support__content--light">
              <img class="image" src="images/service-support/icon-site-visit.png" alt="">
              <h4 data-service-title>
                {{ getData.stage_2_headline }}
              </h4>
              <div
                :class="['hidden', {'big': $i18n.locale === 'fr'}]">
                <div v-html="getData.stage_2_content"></div>

                <template v-if="getData.stage_2_content_additional">
                  <span
                    class="learn-more"
                    data-toggle="modal"
                    data-target="#additional-modal"
                    @click="setAdditionalContent(2)">
                    {{ $t("buttons.learn-more") }}
                  </span>
                </template>
              </div>

              <span
                class="icon"
                @click="openDetail($event)" >
              </span>
            </div>
            <div class="single-support__foot">
              <span>
                {{ getData.stage_2_duration }}
              </span>
            </div>
          </div>
          <div class="single-support">
            <div class="single-support__content">
              <img class="image" src="images/service-support/icon-installation.png" alt="">
              <h4 data-service-title>
                {{ getData.stage_3_headline }}
              </h4>
              <div
                :class="['hidden', {'big': $i18n.locale === 'fr'}]">
                <div v-html="getData.stage_3_content"></div>

                <template v-if="getData.stage_3_content_additional">
                  <span
                    class="learn-more"
                    data-toggle="modal"
                    data-target="#additional-modal"
                    @click="setAdditionalContent(3)">
                    {{ $t("buttons.learn-more") }}
                  </span>
                </template>
              </div>

              <span
                class="icon"
                @click="openDetail($event)" >
              </span>
            </div>
            <div class="single-support__foot">
              <span>
                {{ getData.stage_3_duration }}
              </span>
            </div>
          </div>
          <div class="single-support">
            <div
              v-if="plans"
              class="single-support__cta ra-gtm-event"
              data-toggle="modal"
              data-target="#plans-modal"
              data-gtm-section="Service & Support"
              data-gtm-subsection=""
              data-gtm-label="Open Plans Breakdown Modal"
              @click="clickTracked($event)">
              <span>
                {{ getData.plans_breakdown_trigger }}
              </span>
            </div>
            <div class="single-support__content single-support__content--light">
              <img class="image" src="images/service-support/icon-service-support.png" alt="">
              <h4 data-service-title>
                {{ getData.stage_4_headline }}
              </h4>
              <div
                :class="['hidden', {'big': $i18n.locale === 'fr'}]">
                <div v-html="getData.stage_4_content"></div>

                <template v-if="getData.stage_4_content_additional">
                  <span
                    class="learn-more"
                    data-toggle="modal"
                    data-target="#additional-modal"
                    @click="setAdditionalContent(4)">
                    {{ $t("buttons.learn-more") }}
                  </span>
                </template>
              </div>

              <span
                class="icon"
                @click="openDetail($event)" >
              </span>
            </div>
            <div class="single-support__foot">
              <span>
                {{ getData.stage_4_duration }}
              </span>
            </div>
          </div>
          <div class="single-support">
            <div
              class="single-support__cta ra-gtm-event"
              data-toggle="modal"
              data-target="#grow-modal"
              data-gtm-section="Service & Support"
              data-gtm-subsection=""
              data-gtm-label="Open Grow & Thrive"
              @click="clickTracked($event)">
              <span>
                {{ getData.grow_thrive_trigger }}
              </span>
            </div>
            <div class="single-support__content">
              <img class="image" src="images/service-support/icon-grow.png" alt="">
              <h4 data-service-title>
                {{ getData.stage_5_headline }}
              </h4>
              <div
                :class="['hidden', {'big': $i18n.locale === 'fr'}]">
                <div v-html="getData.stage_5_content"></div>

                <template v-if="getData.stage_5_content_additional">
                  <span
                    class="learn-more"
                    data-toggle="modal"
                    data-target="#additional-modal"
                    @click="setAdditionalContent(5)">
                    {{ $t("buttons.learn-more") }}
                  </span>
                </template>
              </div>

              <span
                class="icon"
                @click="openDetail($event)" >
              </span>
            </div>
            <div class="single-support__foot">
              <span>
                {{ getData.stage_5_duration }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Installation Estimator -->
      <div
        v-if="currentPrinter === 1"
        class="calculator">
        <h4 class="title title--small title--blue">
          {{ $t('service-support.estimator.headline') }}
        </h4>
        <div class="calculator-dates">
          <div class="calculator__date">
            <span>
              {{ $t('service-support.estimator.label') }}
            </span>
            <div class="inputs">
              <template v-if="salesPerson.region === 'AMS'">
                <input
                  class="month"
                  type="text"
                  :placeholder="$t('service-support.estimator.month')"
                  ref="month"
                  v-model="initDate.month">
                <input
                  class="day"
                  type="text"
                  :placeholder="$t('service-support.estimator.day')"
                  ref="day"
                  v-model="initDate.day">
              </template>
              <template v-else>
                <input
                  class="day"
                  type="text"
                  :placeholder="$t('service-support.estimator.day')"
                  ref="day"
                  v-model="initDate.day">
                <input
                  class="month"
                  type="text"
                  :placeholder="$t('service-support.estimator.month')"
                  ref="month"
                  v-model="initDate.month">
              </template>
              <input
                class="year"
                type="text"
                :placeholder="$t('service-support.estimator.year')"
                ref="year"
                v-model="initDate.year">
            </div>
          </div>
          <div class="calculator__date calculator__date--result" v-if="isDateFull">
            <span>
              Completion of Install<sup>*</sup>
            </span>
            <input class=full type="text" readonly :value="finalDate">
            <small>
              <sup>*</sup>{{ $t('service-support.estimator.note') }}
            </small>
          </div>
        </div>
        <div class="calculator-actions">
          <div class="action" v-if="!isDateFull">
            <Button
              href="javascript:void(0)"
              data-gtm-section="Service & Support"
              data-gtm-subsection=""
              data-gtm-label="Click Estimate Date"
              @click="estimate">
              {{ $t('service-support.estimator.submit') }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <Modal idModal="preparation-modal">
      <div
        class="carousel slide js-carousel"
        id="site-prep__carousel"
        data-interval="false"
        data-ride="carousel"
        data-wrap="true">
        <div class="carousel-inner">
          <div
            v-for="(item, index) in getSitePrepDataByPrinter"
            :class="[
              'carousel-item',
              {'active': index === 0}
              ]"
            :key="index">
            <div class="preparation__img">
              <img :src="`${cmsURL}assets/${getSitePrepData(index).printer_image}`" alt="">
            </div>
            <div class="preparation__content">
              <h4 class="title title--small">
                {{ getSitePrepData(index).headline_label }}
              </h4>
              <h2 class="title title--white">
                {{ getSitePrepData(index).headline }}
              </h2>
              <small v-html="getSitePrepData(index).content"></small>
            </div>

            <!-- <ShareButton
              v-if="getSitePrepData(index).site_prep_shareable_pdf"
              :shareLabel="`${$t('main-nav.service-support')} - ${getSitePrepData(index).site_prep_trigger_label} ${printerName}`"
              :shareLink="`${cmsURL}assets/${getSitePrepData(index).site_prep_shareable_pdf}`"
              :hash="hashify(`${$t('main-nav.service-support')} - ${getSitePrepData(index).site_prep_trigger_label} ${printerName}`)"
              @share-this="shareThis" /> -->

            <ShareButton
              v-if="getData.site_prep_shareable_pdf"
              :shareLabel="`${$t('main-nav.service-support')} - ${getData.site_prep_trigger_label} ${printerName}`"
              :shareLink="`${cmsURL}assets/${getData.site_prep_shareable_pdf}`"
              :hash="hashify(`${$t('main-nav.service-support')} - ${getData.site_prep_trigger_label} ${printerName}`)"
              @share-this="shareThis" />
          </div>
        </div>

        <a
          v-if="getSitePrepDataByPrinter.length > 1"
          class="carousel-control carousel-control-prev js-prev"
          data-slide="prev"
          href="#site-prep__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17" >
              <polygon class="st0" points="8.6,16.7 0.3,8.4 8.6,0 10.1,1.5 4.3,7.3 17,7.3 17,9.4 4.3,9.4 10.1,15.2 "/>
            </svg>
          </span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          v-if="getSitePrepDataByPrinter.length > 1"
          class="carousel-control carousel-control-next js-nex"
          data-slide="next"
          href="#site-prep__carousel"
          role="button">
          <span class="icon">
            <svg width="17px" height="17px" viewBox="0 0 17 17">
              <polygon class="st0" points="8.6,0 17,8.4 8.6,16.7 7.2,15.2 13,9.4 0.3,9.4 0.3,7.3 13,7.3 7.2,1.5 "/>
            </svg>
          </span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </Modal>

    <Modal idModal="plans-modal" v-if="plans">
      <h2 class="title title--white text-center">
        {{ getData.modal_plans_headline }}
      </h2>

      <div class="modal-table support-table">
        <div class="support-table__col support-table__col--titles">
          <div class="support-table__cell support-table__cell--head-1">
            <h5>&nbsp;</h5>
          </div>
          <div class="support-table__cell">
            <p class="text-left">
              <strong>
                {{ $t('service-support.plans.support') }}
              </strong>
            </p>
          </div>
          <div class="support-table__cell">
            <p class="text-left">
              <strong>
                {{ $t('service-support.plans.parts') }}
              </strong>
            </p>
          </div>
          <div class="support-table__cell support-table__cell--tall" data-plan-row>
            <p class="text-left">
              <strong>
                {{ $t('service-support.plans.on-site') }}
              </strong>
            </p>
          </div>
          <div class="support-table__cell support-table__cell--last">
            <p class="text-left">
              <strong>
                {{ $t('service-support.plans.maintenance') }}
              </strong>
            </p>
          </div>
        </div>

        <template
          v-for="(item, index) in getPlans"
          :key="index">
          <div
            class="support-table__col js-support-table-col"
            >
            <div class="support-table__cell support-table__cell--head-3">
              <h5>
                {{ item.content.name }}
              </h5>
            </div>
            <div class="support-table__cell">
              <p>
                {{ item.content.support_hours }}
              </p>
            </div>
            <div class="support-table__cell">
              <p>
                {{ item.content.parts }}
              </p>
            </div>
            <div
              class="support-table__cell support-table__cell--tall"
              data-plan-row v-html="item.content.on_site_support"></div>
            <div
              class="support-table__cell support-table__cell--last"
              v-html="item.content.maintenance_parts"></div>
          </div>
        </template>
      </div>
    </Modal>

    <Modal idModal="grow-modal">
      <h2 class="title title--white text-center">
        {{ getData.modal_grow_thrive_headline }}
      </h2>

      <div class="modal-table grow-table">
        <div
          class="grow-table__col js-grow-table-col"
          v-for="(item, index) in getData.modal_grow_table"
          :key="index">
          <div class="grow-table__cell grow-table__cell--head">
            <h5>
              {{ item.column_headline }}
            </h5>
          </div>
          <div
            :class="['grow-table__cell',
            {'grow-table__cell--tall': idx === 0},
            {'grow-table__cell--last': idx === item.content.length - 1}]"
            data-grow-row
            v-for="(cell, idx) in item.content"
            :key="idx"
            v-html="cell.cell"></div>
        </div>
      </div>
    </Modal>

    <!-- Additional Content for stage -->
    <Modal idModal="additional-modal">
      <div class="wrap-additional-content">
        <h2 class="title title--white text-center">
          {{ additionalContent.headline }}
        </h2>

        <div class="content">
          <div v-html="additionalContent.content"></div>
        </div>
      </div>
    </Modal>
    <!-- END Additional Content for stage -->

    <ShareButton
      v-if="getData.shareable_pdf"
      :shareLabel="`${$t('main-nav.service-support')} ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :hash="hashify(`${$t('main-nav.service-support')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import Button from '@/components/ui/Button.vue';
import Modal from '@/components/modal/Modal.vue';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'ServiceSupport',
  mixins: [filters, defaultMixins],
  components: {
    Button,
    ShareButton,
    Modal,
  },
  data() {
    return {
      info: null,
      plans: null,
      sitePrep: null,
      currentAdditionalModal: 1,
      showContent1: false,
      showContent2: false,
      showContent3: false,
      showContent4: false,
      showContent5: false,
      initDate: {
        month: '',
        day: '',
        year: '',
      },
      isDateFull: false,
      shareLink: 'https://pagewide.hp-blueprint.com/shareable/hp-blueprint-pagewide-webpress-service-and-support.pdf',
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/service_and_support`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });

    // Get Site Preparation
    axios.get(`${endpoints.CMS}items/service_and_support_site_prep`, {
      params: {
        fields: `*,
        translations.*,
        printer.printers_id`,
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.sitePrep = data;
      });

    // Get Plans breakdown
    axios.get(`${endpoints.CMS}items/service_and_support_modal_plans`, {
      params: {
        fields: `*,
        translations.*,
        related_region.region_id.identifier,
        printer.printers_id`,
      },
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1',
    })
      .then(({ data: { data } }) => {
        this.plans = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    openDetail(e) {
      e.target.classList.toggle('active');
      $(e.target).prev().fadeToggle();
    },
    setAdditionalContent(stage) {
      this.currentAdditionalModal = stage;
    },
    estimate() {
      if (this.salesPerson.region === 'AMS') {
        if (!this.initDate.month) {
          this.$refs.month.classList.add('error');
          this.isDateFull = false;
          return false;
        }

        if (!this.initDate.day) {
          this.$refs.day.classList.add('error');
          this.isDateFull = false;
          return false;
        }
      } else {
        if (!this.initDate.day) {
          this.$refs.day.classList.add('error');
          this.isDateFull = false;
          return false;
        }

        if (!this.initDate.month) {
          this.$refs.month.classList.add('error');
          this.isDateFull = false;
          return false;
        }
      }

      if (!this.initDate.year) {
        this.$refs.year.classList.add('error');
        this.isDateFull = false;
        return false;
      }

      this.isDateFull = true;
      return true;
    },
  },
  computed: {
    // Maps State to data
    ...mapState([
      'currentPrinter',
    ]),
    labelClass() {
      const printerClass = {
        1: 't250',
        2: 't490',
        3: 'a2200',
      };

      return printerClass[this.currentPrinter];
    },
    additionalContent() {
      return ({
        headline: this.getData[`stage_${this.currentAdditionalModal}_headline`],
        content: this.getData[`stage_${this.currentAdditionalModal}_content_additional`],
      });
    },
    getPlans() {
      return this.plans
        .filter((item) => item.printer
          .some((printer) => printer.printers_id === this.currentPrinter))
        .map((plan) => {
          // Filter translated items
          const translatedPlan = plan.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Set English for fallback
          const fallbackPlan = plan.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            ...plan,
            content: translatedPlan || fallbackPlan,
          };
        })
        .map((plan) => (
          {
            ...plan,
            region: plan.related_region
              .map((region) => region.region_id.identifier),
          }
        ))
        .filter((plan) => plan.region.includes(this.salesPerson.region));
    },
    finalDate() {
      if (this.initDate.month && this.initDate.day && this.initDate.year) {
        const dt = new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day);

        dt.setDate(dt.getDate() + 91);

        const dd = dt.getDate();
        const mm = dt.getMonth() + 1;
        const y = dt.getFullYear();
        let newFormattedDate;

        if (this.salesPerson.region === 'AMS') {
          newFormattedDate = `${mm} / ${dd} / ${y}`;
        } else {
          newFormattedDate = `${dd} / ${mm} / ${y}`;
        }

        return newFormattedDate;
      }

      return '';
    },
    // Get current Printer's data
    getSitePrepDataByPrinter() {
      if (this.sitePrep) {
        const currentPrinterSitePrepData = this.sitePrep
          .filter((item) => item.printer
            .some((printer) => printer.printers_id === this.currentPrinter));

        return currentPrinterSitePrepData;
      }

      return false;
    },
    // Get Translated data
    getSitePrepData() {
      return (idx) => {
        if (this.sitePrep) {
          // Set fallback to english data
          const [defaultEnglishData] = this.getSitePrepDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.fallbackLocale);

          // Get current language data
          const [translatedData] = this.getSitePrepDataByPrinter[idx].translations
            .filter((item) => item.languages_code === this.$i18n.locale);

          return translatedData || defaultEnglishData;
        }

        return false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/service-support.scss';
</style>
