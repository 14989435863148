<template>
  <div :class="['virtual-tour__section', {'backdrop': !bg}, customClass]">
    <!-- eslint-disable max-len -->
    <div class="custom-bg js-custom-tour-bg" v-if="bg">
      <img :src="`${cmsURL}assets/${bg}`" />
    </div>

    <div class="virtual-tour__title text-center">
      <h2 class="title js-title">
        {{ data.headline }}
      </h2>
      <h5 class="title title--small js-subtitle" v-if="data.description">
        {{ data.description }}
      </h5>
    </div>

    <template v-if="data.media_items">
      <template v-if="data.media_items.length < 2">
        <Media
          v-for="(item, index) in data.media_items"
          class="js-media-wrapper"
          :key="index"
          :poster="item.poster"
          :src="item.source"
          :title="data.media_caption"
          :type="item.type">
          <template v-slot:content>
            <div class="content" v-html="data.media_content"></div>
          </template>
        </Media>
      </template>
      <template v-else>
        <div class="media-gallery">
          <Media
            v-for="(item, index) in data.media_items"
            :class="['js-media-wrapper', `js-media-wrapper--${index}`,
            {'active': index === 0}]"
            :key="index"
            :poster="item.poster"
            :src="item.source"
            :title="data.media_caption"
            :type="item.type">
            <template v-slot:content>
              <div class="content" v-html="data.media_content"></div>
            </template>
          </Media>

          <div class="media-gallery__thumbs">
            <a
              v-for="(item, index) in data.media_items"
              :class="['single-thumb', 'js-media-nav',
              {'active': index === 0}]"
              href="javascript:void(0)"
              :key="index"
              @click="changeImg(`${index}`, $event.target)">
              <img
                :alt="data.media_caption"
                class="img-fluid object-fit-cover-center"
                :src="(item.type === 'video') ? item.poster : item.source" />
            </a>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import $ from 'jquery';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';
import { showElement } from '@/common/animations';

import Media from '@/components/ui/Media.vue';

export default {
  name: 'TourSection',
  mixins: [defaultMixins],
  components: {
    Media,
  },
  props: {
    bg: String,
    data: Object,
    customClass: String,
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
    };
  },
  mounted() {
    this.hideMainTabs();

    gsap.timeline()
      .add(showElement('.js-title', 'top'))
      .add(showElement('.js-subtitle', 'top'))
      .add(showElement('.js-media-wrapper', 'top'));
  },
  methods: {
    changeImg(target, _this) {
      const video = document.getElementsByTagName('video');

      if (video.length > 0) {
        for (let index = 0; index < video.length; index += 1) {
          video[index].pause();
        }
      }

      $('.js-media-nav').removeClass('active');
      $(_this).parent().addClass('active');

      $('.js-media-wrapper').hide().removeClass('active');
      $(`.js-media-wrapper--${target}`).fadeIn().addClass('active');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/scss/views/virtual-tour/sections.scss';
</style>
